export const displayByOptions = [
  { value: 'global_connectedness', label: 'Global Flows' },
  { value: 'individual_flow_trends', label: 'Individual Flows' },
];

export const startYearOptions = [
  { value: 2001, label: '2001' },
  { value: 2002, label: '2002' },
  { value: 2003, label: '2003' },
  { value: 2004, label: '2004' },
  { value: 2005, label: '2005' },
  { value: 2006, label: '2006' },
  { value: 2007, label: '2007' },
  { value: 2008, label: '2008' },
  { value: 2009, label: '2009' },
  { value: 2010, label: '2010' },
  { value: 2011, label: '2011' },
  { value: 2012, label: '2012' },
  { value: 2013, label: '2013' },
  { value: 2014, label: '2014' },
  { value: 2015, label: '2015' },
  { value: 2016, label: '2016' },
  { value: 2017, label: '2017' },
  { value: 2018, label: '2018' },
  { value: 2019, label: '2019' },
  { value: 2020, label: '2020' },
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
  { value: 2023, label: '2023' },
];

export const endYearOptions = [
  { value: 2001, label: '2001' },
  { value: 2002, label: '2002' },
  { value: 2003, label: '2003' },
  { value: 2004, label: '2004' },
  { value: 2005, label: '2005' },
  { value: 2006, label: '2006' },
  { value: 2007, label: '2007' },
  { value: 2008, label: '2008' },
  { value: 2009, label: '2009' },
  { value: 2010, label: '2010' },
  { value: 2011, label: '2011' },
  { value: 2012, label: '2012' },
  { value: 2013, label: '2013' },
  { value: 2014, label: '2014' },
  { value: 2015, label: '2015' },
  { value: 2016, label: '2016' },
  { value: 2017, label: '2017' },
  { value: 2018, label: '2018' },
  { value: 2019, label: '2019' },
  { value: 2020, label: '2020' },
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
  { value: 2023, label: '2023' },
];

export const flowtrendsOptions = [
  { value: 'Merchandise Exports', label: 'Merchandise Exports' },
  { value: 'Foreign Value Added', label: 'Foreign Value Added' },
  { value: 'Announced Greenfield FDI', label: 'Announced Greenfield FDI' },
  { value: 'Mergers and Acquisitions Transactions', label: 'Mergers and Acquisitions Transactions' },
  { value: 'FDI Inward Stock', label: 'FDI Inward Stock' },
  { value: 'FDI Inflows', label: 'FDI Inflows' },
  {
    value: 'Portfolio Equity Assets Stock',
    label: 'Portfolio Equity Assets Stock',
  },
  {
    value: 'Scientific Research Collaboration',
    label: 'Scientific Research Collaboration',
  },
  {
    value: 'Charges for the Use of Intellectual Property',
    label: 'Charges for the Use of Intellectual Property',
  },
  {
    value: 'International Patent Applications',
    label: 'International Patent Applications',
  },
  { value: 'Tourist Arrivals', label: 'Tourist Arrivals' },
  { value: 'International Students', label: 'International Students' },
  { value: 'Immigration', label: 'Immigration' },
];

export const trendsOptions = [
  {
    value: 'overall',
    label: 'Global Connectedness',
    glossarySel: 'trendsOverall',
  },
  { value: 'trade', label: 'Trade', glossarySel: 'trendsTrade' },
  { value: 'capital', label: 'Capital', glossarySel: 'trendsCapital' },
  {
    value: 'information',
    label: 'Information',
    glossarySel: 'trendsInformation',
  },
  { value: 'people', label: 'People', glossarySel: 'trendsPeople' },
];

export const trendsSubOptions = [
  { value: 'depth', label: 'Depth', glossarySel: 'trendsSubDepth' },
  { value: 'breadth', label: 'Breadth', glossarySel: 'trendsSubBreadth' },
];

export const flowtrendsSubOptions = [
  { value: 'depth', label: 'Depth' },
  { value: 'breadth', label: 'Breadth' },
];
