import React, { useState, useRef, useCallback } from 'react';
import { Radio, RadioGroup, SelectPicker } from 'rsuite';
import Table from './Table';
import Glossary from './Glossary';
import RoutesSwitcher from './RoutesSwitcher';
import CountryHeading from './CountryHeading';
import DownloadData from './DownloadData';
import styles from '../styles/CountryResults.module.scss';
import tableStyles from '../styles/Table.module.scss';
import { yearOptions, regionOptions } from '../options/countryOptions';
import RenderedState from './RenderedState';
import useGlobalState from '../hooks/useGlobalState';
import useGlossary from '../hooks/useGlossary';
import trackEvent from '../helpers/tracking';

function filterData(region, data) {
  if (region === 'global') return data;
  if (region === 'g7') return data.filter((el) => el.g7g20 === 'G7');
  if (region === 'g20') { return data.filter((el) => el.g7g20 === 'G20' || el.g7g20 === 'G7'); }
  if (region === 'oecd') return data.filter((el) => el.oecd);
  if (region === 'advec') { return data.filter((el) => el.advem === 'Advanced Economies'); }
  if (region === 'edec') { return data.filter((el) => el.advem === 'Emerging and Developing Economies'); }
  if (region === 'asean') return data.filter((el) => el.euasean === 'ASEAN');
  if (region === 'eu') { return data.filter((el) => el.euasean === 'European Union'); }
  return data.filter((el) => el.region === region);
}

function filterPossibleYears(allYearOptions, currentRegionData) {
  return allYearOptions.filter((el) => Object.keys(currentRegionData).includes(el.label));
}

function CountryResults() {
  const [field, setField] = useState('global_connectedness');
  const [year, setYear] = useState(2022);
  const [region, setRegion] = useState('global');
  const [userLimit, setUserLimit] = useState(25);
  const {
    state: { dataLoaded, countryResults, glossaryVisible },
  } = useGlobalState();
  const g = useGlossary;
  const snapshotWrapper = useRef();

  const allData = filterData(region, countryResults[field][year]);
  const max = allData.length;
  const limit = Math.min(userLimit, max);

  const handleChange = (type, value) => {
    trackEvent({
      event: 'filter_change',
      type: `${type}`,
      value: `${value}`,
    });

    if (type === 'year') setYear(value);
    if (type === 'field') setField(value);
    if (type === 'region') setRegion(value);
  };

  const handleLimitChange = useCallback(
    (dif) => {
      trackEvent({
        event: 'limit_change',
        formLocation: 'country_results',
        value: dif <= 25 ? dif : 'show_all',
      });

      if (limit + dif < 25) {
        setUserLimit(25);
      } else if (limit + dif > max) {
        setUserLimit(max);
      } else if (limit === max && dif < 0) {
        setUserLimit(Math.floor(Math.abs(max / dif)) * Math.abs(dif));
      } else {
        setUserLimit((prev) => prev + dif);
      }
    },
    [limit, max],
  );

  return (
    <>
      <div className={styles.header}>
        <RoutesSwitcher />
        <div className={styles.firstFilterWrapper}>
          <div className={styles.row}>
            <span className={styles.subHeading}>
              {g({ key: 'selectRegion' })}
            </span>
            <SelectPicker
              value={region}
              onChange={(value) => handleChange('region', value)}
              data={regionOptions}
              cleanable={false}
              searchable={false}
              className={styles.selectRegion}
              menuClassName="gciClient-menu-dropdown"
            />
          </div>
        </div>
      </div>
      <div className={styles.filterWrapper}>
        <span className={styles.subHeading}>{g({ key: 'countrySYC' })}</span>
        <div>
          <RadioGroup
            name="field"
            value={field}
            onChange={(value) => handleChange('field', value)}
            className={styles.radioFilterGroup}
          >
            <Radio value="global_connectedness" className={styles.radioFilter}>
              {g({ key: 'countryOverall' })}
            </Radio>
            <Radio value="trade" className={styles.radioFilter}>
              {g({ key: 'countryTrade' })}
            </Radio>
            <Radio value="capital" className={styles.radioFilter}>
              {g({ key: 'countryCapital' })}
            </Radio>
            <Radio value="information" className={styles.radioFilter}>
              {g({ key: 'countryInformation' })}
            </Radio>
            <Radio value="people" className={styles.radioFilter}>
              {g({ key: 'countryPeople' })}
            </Radio>
          </RadioGroup>
        </div>
      </div>
      <div className={`${tableStyles.wrapper} spec--table-wrapper`}>
        <div className={tableStyles.subHeader}>
          <CountryHeading region={region} year={year} field={field} />
          <div className={styles.controls}>
            <div>
              <span className={styles.subHeading}>
                {g({ key: 'selectLimit' })}
              </span>
              <div className={styles.changeLimitButtons}>
                <button
                  type="button"
                  onClick={() => handleLimitChange(-25)}
                  disabled={limit <= 25}
                >
                  -
                </button>
                <span>{limit}</span>
                <button
                  type="button"
                  onClick={() => handleLimitChange(25)}
                  disabled={limit === max}
                >
                  +
                </button>
              </div>
            </div>
            <div>
              <span className={styles.subHeading}>Select Year</span>
              <SelectPicker
                value={year}
                onChange={(value) => handleChange('year', value)}
                onClean={() => handleChange('year', 2019)}
                data={filterPossibleYears(yearOptions, countryResults[field])}
                searchable={false}
                className={styles.calendarIcon}
                cleanable={false}
                menuClassName="gciClient-menu-dropdown"
              />
            </div>
            {dataLoaded && (
              <DownloadData
                node={snapshotWrapper}
                data={allData}
                source="Country Results"
                dataFormat="table"
              />
            )}
          </div>
        </div>
        {dataLoaded ? (
          <div ref={snapshotWrapper} className={styles.snapshotWrapper}>
            <Table data={allData} region={region} limit={limit} />
          </div>
        ) : (
          <RenderedState />
        )}
        <div>
          <button
            type="button"
            className={styles.showMore}
            onClick={() => handleLimitChange(25)}
            disabled={limit >= max}
          >
            Show More
          </button>
          <button
            type="button"
            className={styles.showMore}
            onClick={() => handleLimitChange(max)}
            disabled={limit >= max}
          >
            Show All
          </button>
        </div>
        {glossaryVisible && <Glossary />}
      </div>
    </>
  );
}

export default CountryResults;
