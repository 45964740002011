import React from 'react';
import PropTypes from 'prop-types';
import useGlobalState from '../hooks/useGlobalState';
import styles from '../styles/RenderedState.module.scss';
import warnIcon from '../assets/images/icon-warning.svg';

function RenderedState({ simpleDisplay }) {
  const {
    state: { dataSet, dataLoaded, dataLoadingError },
  } = useGlobalState();

  return (
    <>
      {!dataLoaded && (
        <>
          <div className={styles.loader} />
          <div className={styles.message}>Loading data...</div>
        </>
      )}
      {
        dataLoaded
          && !dataSet
          // eslint-disable-next-line no-console
          && console.log('There was an error while rendering the app data...')
        // eslint-enable-next-line no-console
      }
      {dataLoadingError && (
        <div className={styles.message}>
          There was an error while loading the app data...
        </div>
      )}
      {dataLoaded && !simpleDisplay && (
        <>
          <div className={styles.icon}>
            <img src={warnIcon} alt="Warning Icon" />
          </div>
          <div className={styles.message}>
            No data found. Please select data.
          </div>
        </>
      )}
    </>
  );
}

export default RenderedState;

RenderedState.propTypes = {
  simpleDisplay: PropTypes.bool,
};

RenderedState.defaultProps = {
  simpleDisplay: false,
};
