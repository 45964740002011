export const initialTrendsFilter = {
  overall: true,
  trade: false,
  people: false,
  capital: false,
  information: false,
};

export const initialTrendsSubFilter = {
  overall: ['depth'],
  trade: ['depth'],
  capital: ['depth'],
  people: ['depth'],
  information: ['depth'],
};

export const initialFlowtrendsFilter = {
  'Merchandise Exports': true,
  'Foreign Value Added': false,
  'Announced Greenfield FDI': false,
  'Mergers and Acquisitions Transactions': false,
  'FDI Inward Stock': false,
  'FDI Inflows': false,
  'Portfolio Equity Assets Stock': false,
  'Scientific Research Collaboration': false,
  'Charges for the Use of Intellectual Property': false,
  'International Patent Applications': false,
  'Tourist Arrivals': false,
  'International Students': false,
  Immigration: false,
};

export const initialFlowtrendsSubFilter = {
  'Merchandise Exports': 'depth',
  'Foreign Value Added': 'depth',
  'Announced Greenfield FDI': 'depth',
  'Mergers and Acquisitions Transactions': 'depth',
  'FDI Inward Stock': 'depth',
  'FDI Inflows': 'depth',
  'Portfolio Equity Assets Stock': 'depth',
  'Scientific Research Collaboration': 'depth',
  'Charges for the Use of Intellectual Property': 'depth',
  'International Patent Applications': 'depth',
  'Tourist Arrivals': 'depth',
  'International Students': 'depth',
  Immigration: 'depth',
};
