function CustomEvent(event, params) {
  const p = params || { bubbles: false, cancelable: false, detail: null };
  const evt = document.createEvent('CustomEvent');
  evt.initCustomEvent(event, p.bubbles, p.cancelable, p.detail);
  return evt;
}

const trackEvent = (args) => {
  if (typeof window.CustomEvent !== 'function') window.CustomEvent = CustomEvent;

  document.dispatchEvent(
    new CustomEvent('dataLayer.push', {
      detail: {
        ...args,
      },
    }),
  );

  return true;
};

export default trackEvent;
