import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Whisper } from 'rsuite';
import useGlobalState from './useGlobalState';
import styles from '../styles/elements.module.scss';
import {
  glossaryTrendsMappings,
  glossaryFlowtrendsMappings,
  glossaryCountryMappings,
} from '../options/glossaryMappings';

export const glossaryMappings = {
  ...glossaryTrendsMappings,
  ...glossaryFlowtrendsMappings,
  ...glossaryCountryMappings,
  'Global Results': {
    label: 'Global Results',
    explanation: 'Click here to see and customize global-level results',
    alignedLeft: true,
  },
  'Country Results': {
    label: 'Country Results',
    explanation: 'Click here to see and customize the country ranking',
  },
  selectDate: {
    label: 'Select Date',
    explanation: 'Please select the initial year and end year to display',
  },
};

function useGlossary({ key }) {
  const {
    state: { hasDesktopFeatures },
  } = useGlobalState();

  const popover = (
    <Popover style={{ maxWidth: '300px' }} className={styles.popover}>
      <span>{glossaryMappings[key].explanation}</span>
    </Popover>
  );

  return (
    hasDesktopFeatures ? (
      <Whisper
        trigger="hover"
        speaker={popover}
        delayHide={200}
        placement={
            glossaryMappings[key].alignedLeft ? 'bottomStart' : 'bottomEnd'
          }
      >
        <span>{glossaryMappings[key].label}</span>
      </Whisper>
    ) : (
      <span>{glossaryMappings[key].label}</span>
    )
  );
}

export default useGlossary;

useGlossary.propTypes = {
  key: PropTypes.string.isRequired,
};
