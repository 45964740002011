export const yearOptions = [
  { value: 2001, label: '2001' },
  { value: 2002, label: '2002' },
  { value: 2003, label: '2003' },
  { value: 2004, label: '2004' },
  { value: 2005, label: '2005' },
  { value: 2006, label: '2006' },
  { value: 2007, label: '2007' },
  { value: 2008, label: '2008' },
  { value: 2009, label: '2009' },
  { value: 2010, label: '2010' },
  { value: 2011, label: '2011' },
  { value: 2012, label: '2012' },
  { value: 2013, label: '2013' },
  { value: 2014, label: '2014' },
  { value: 2015, label: '2015' },
  { value: 2016, label: '2016' },
  { value: 2017, label: '2017' },
  { value: 2018, label: '2018' },
  { value: 2019, label: '2019' },
  { value: 2020, label: '2020' },
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
];

export const regionOptions = [
  { value: 'global', label: 'Global' },
  { value: 'g7', label: 'G7' },
  { value: 'g20', label: 'G20' },
  { value: 'oecd', label: 'OECD' },
  { value: 'East Asia & Pacific', label: 'East Asia & Pacific' },
  { value: 'Europe', label: 'Europe' },
  { value: 'Middle East & N. Africa', label: 'Middle East & N. Africa' },
  { value: 'North America', label: 'North America' },
  { value: 'S. & C. America, Caribbean', label: 'S. & C. America, Caribbean' },
  { value: 'South & Central Asia', label: 'South & Central Asia' },
  { value: 'Sub-Saharan Africa', label: 'Sub-Saharan Africa' },
  { value: 'advec', label: 'Advanced Economies' },
  { value: 'edec', label: 'Emerging and Developing Economies' },
  { value: 'asean', label: 'ASEAN' },
  { value: 'eu', label: 'European Union' },
];

export const headingMappings = {
  global_connectedness: { label: 'Global Connectedness' },
  trade: { label: 'Trade' },
  capital: { label: 'Capital' },
  information: { label: 'Information' },
  people: { label: 'People' },
  global: { label: 'the world' },
  g7: { label: 'the G7' },
  g20: { label: 'the G20' },
  oecd: { label: 'the OECD' },
  'East Asia & Pacific': { label: 'East Asia & Pacific' },
  Europe: { label: 'Europe' },
  'Middle East & N. Africa': { label: 'Middle East & N. Africa' },
  'North America': { label: 'North America' },
  'S. & C. America, Caribbean': { label: 'S. & C. America, Caribbean' },
  'South & Central Asia': { label: 'South & Central Asia' },
  'Sub-Saharan Africa': { label: 'Sub-Saharan Africa' },
  advec: { label: 'the Advanced Economies' },
  edec: {
    label: 'the Emerging and Developing Economies',
  },
  asean: { label: 'ASEAN' },
  eu: { label: 'the European Union' },
};
