import React from 'react';
import { routes } from '../options/routesOptions';
import styles from '../styles/RoutesSwitcher.module.scss';
import useGlobalState from '../hooks/useGlobalState';
import trackEvent from '../helpers/tracking';
import useGlossary from '../hooks/useGlossary';

function Routes() {
  const {
    state: { route },
    setState,
  } = useGlobalState();

  const g = useGlossary;

  const handleRouteChange = (value) => {
    trackEvent({
      event: 'route_change',
      value: `${value}`,
    });

    setState({ route: value });
  };

  return (
    <div className={`${styles.wrapper} spec--routes-switcher`}>
      {routes.map((r) => (
        <button
          className={`${styles.routeButton} ${
            route === r.value ? styles.active : styles.inactive
          } spec--routes-switcher-button`}
          onClick={() => handleRouteChange(r.value)}
          key={`route-option-${r.value}`}
          type="button"
        >
          <img src={r.icon} alt={`${r.label}-icon`} />
          {g({ key: r.label })}
        </button>
      ))}
    </div>
  );
}

export default Routes;
