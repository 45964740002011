import React from 'react';
import { useTable, useSortBy } from 'react-table';
import PropTypes, { string, number } from 'prop-types';
import styles from '../styles/Table.module.scss';
import PDFIcon from '../assets/images/icon-file-pdf.svg';
import iconArrowDown from '../assets/images/icon-arrow-down.svg';
import useGlobalState from '../hooks/useGlobalState';
import useGlossary from '../hooks/useGlossary';
import trackEvent from '../helpers/tracking';

function CountryResultsTable({ data, region, limit }) {
  const {
    state: { countryData },
  } = useGlobalState();
  const g = useGlossary;

  const headerToGlossary = {
    Rank: 'rank',
    'Gains/ Losses': 'gainsLosses',
    Country: 'country',
    Breadth: 'breadth',
    Depth: 'depth',
    'Country Profile': 'countryProfile',
    Score: 'score',
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Rank', accessor: 'rank' },
      {
        Header: 'Gains/ Losses',
        accessor: 'change',
        sortInverted: true,
        sortType: 'basic',
      },
      { Header: 'Country', accessor: 'country' },
      {
        Header: 'Score',
        accessor: 'score',
        sortType: 'basic',
        sortInverted: true,
      },
      {
        Header: 'Depth',
        accessor: 'depth_score',
        sortType: 'basic',
        sortInverted: true,
      },
      {
        Header: 'Breadth',
        accessor: 'breadth_score',
        sortType: 'basic',
        sortInverted: true,
      },
      {
        Header: 'Country Profile',
        accessor: 'pdf_download',
        disableSortBy: true,
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [
      {
        id: 'rank',
        desc: false,
      },
    ],
  };

  const { rows, prepareRow, headers } = useTable(
    {
      columns,
      data,
      initialState,
      autoResetSortBy: false,
    },
    useSortBy,
  );

  const allFiles = ((ctx) => {
    const keys = ctx.keys();
    const values = keys.map(ctx);
    return keys.reduce((o, k, i) => {
      const ob = o;
      ob[k] = values[i];
      return ob;
    }, {});
  })(require.context('../assets/flags/', true, /.*/));

  const getFlag = (country) => {
    let link;

    const c = country.toLowerCase()
      .replace(/\s/g, '-')
      .replace(/[()]/g, '')
      .replace(/ü/g, 'ue')
      .replace(/ô/, 'o')
      .replace(/ã/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i');

    try {
      link = allFiles[`./${c}.svg`];
    } catch (error) {
      console.log(error);
    }

    if (link) {
      return <img src={link} alt={country} className={styles.countryFlag} />;
    }
    return <span className={styles.emptyFlag} />;
  };

  const getPDFLink = (iso) => {
    if (!countryData) return false;
    const country = countryData.filter((c) => c.iso === iso)[0];

    if (!country) return false;

    if (country.infoPDF) {
      return (
        <a
          href={`${country.infoPDF}`}
          alt={`PDF Download Link ${country.title}`}
          target="_blank"
          rel="noopener noreferrer"
          download
          onClick={() => trackEvent({
            event: 'download_country_profile',
            value: country.title,
          })}
        >
          <img src={PDFIcon} alt={`PDF Download ${country.title}`} />
        </a>
      );
    }
    return <img src={PDFIcon} alt={`PDF Download ${country.title}`} />;
  };

  const renderCell = (cell) => {
    const sizeFactor = 80;
    if (cell.column.id === 'rank' && region !== 'global') {
      return <span>{cell.row.index + 1}</span>;
    }
    if (cell.column.id === 'breadth_score') {
      return (
        <span
          className={styles.breadthCell}
          style={{
            width: `${(cell.row.values.breadth_score / cell.row.values.score) * sizeFactor}px`,
          }}
        >
          <span className={styles.popoverScore}>
            {cell.row.values.breadth_score.toFixed(0)}
          </span>
        </span>
      );
    }
    if (cell.column.id === 'score') {
      return (
        <span>
          {cell.value.toFixed(1)}
        </span>
      );
    }
    if (cell.column.id === 'depth_score') {
      return (
        <span
          className={styles.depthCell}
          style={{
            width: `${(cell.row.values.depth_score / cell.row.values.score) * sizeFactor}px`,
          }}
        >
          <span className={styles.popoverScore}>
            {cell.row.values.depth_score.toFixed(0)}
          </span>
        </span>
      );
    }
    if (cell.column.id === 'change') {
      return (
      // prettier-ignore
        <span className={`${cell.value > 0 && styles.changePositive} ${cell.value < 0 && styles.changeNegative}`}>
          {cell.value !== 0 ? cell.value : ''}
        </span>
      );
    }
    if (cell.column.id === 'country') {
      return (
        <span>
          {getFlag(cell.value)}
          {cell.value}
        </span>
      );
    }
    if (cell.column.id === 'pdf_download') {
      return (
        <span className={styles.pdfDownloadCell}>
          {getPDFLink(cell.row.original.iso)}
        </span>
      );
    }
    return cell.value;
  };

  const limitedData = rows.slice(0, limit);

  return (
    <table className={styles.general}>
      <thead>
        <tr>
          {headers.map((header) => (
            <th
                /* eslint-disable react/jsx-props-no-spreading */
              {...header.getHeaderProps(header.getSortByToggleProps())}
                /* eslint-enable react/jsx-props-no-spreading */
              className={styles[header.id]}
            >
              {g({ key: headerToGlossary[header.render('Header')] })}
              <span>
                {(() => {
                  if (header.isSorted) {
                    if (header.isSortedDesc) {
                      return (
                        <img
                          src={iconArrowDown}
                          style={{
                            marginLeft: '.3em',
                            transform: 'rotate(180deg) translateY(.1em)',
                          }}
                          alt="sort-des"
                        />
                      );
                    }
                    return (
                      <img
                        src={iconArrowDown}
                        style={{
                          marginLeft: '.3em',
                          transform: 'translateY(-.1em)',
                        }}
                        alt="sort-asc"
                      />
                    );
                  }
                  return (
                    <img
                      src={iconArrowDown}
                      style={{
                        marginLeft: '.3em',
                        opacity: '0',
                      }}
                      alt="placeholder"
                    />
                  );
                })()}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {limitedData.map((row) => {
          prepareRow(row);

          return (
          /* eslint-disable react/jsx-props-no-spreading */
            <tr {...row.getRowProps()}>
              {/* eslint-enable react/jsx-props-no-spreading */}
              {row.cells.map((cell) => (
                /* eslint-disable react/jsx-props-no-spreading */
                <td
                  {...cell.getCellProps()}
                  className={styles[cell.column.id]}
                >
                  {/* eslint-enable react/jsx-props-no-spreading */}
                  {renderCell(cell)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default CountryResultsTable;

CountryResultsTable.propTypes = {
  data: PropTypes.arrayOf({
    advem: string,
    breadth_score: number,
    change: number,
    country: string,
    depth_score: number,
    euasean: string,
    iso: string,
    oecd: number,
    rank: number,
    region: string,
  }).isRequired,
  region: string.isRequired,
  limit: number.isRequired,
};
