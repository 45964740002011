export const glossaryFlowtrendsMappings = {
  flowtrendsSYC: {
    label: 'Select your choice',
    explanation: 'Please select the flow you would like to display',
    alignedLeft: true,
  },
  'Merchandise Exports Depth': {
    label: 'Depth',
    explanation: 'Merchandise exports as a percentage of GDP',
    alignedLeft: true,
  },
  'Merchandise Exports Breadth': {
    label: 'Breadth',
    explanation: 'Geographic distribution of merchandise exports',
    alignedLeft: true,
  },
  'Foreign Value Added Depth': {
    label: 'Depth',
    explanation: 'Foreign value added as a share of total value added; equivalent to share of output that ultimately ends up in a different country from where it was produced',
    alignedLeft: true,
  },
  'Announced Greenfield FDI Depth': {
    label: 'Depth',
    explanation: 'Announced greenfield FDI estimated value as a percentage of GDP',
    alignedLeft: true,
  },
  'Announced Greenfield FDI Breadth': {
    label: 'Breadth',
    explanation: 'Geographic distribution of announced greenfield FDI by project count',
  },
  'Mergers and Acquisitions Transactions Depth': {
    label: 'Depth',
    explanation: 'International mergers and Acquisitions (M&A) transactions value as a percentage of all M&A transactions',
    alignedLeft: true,
  },
  'Mergers and Acquisitions Transactions Breadth': {
    label: 'Breadth',
    explanation: 'Geographic distribution of mergers and acquisitions transactions',
  },
  'FDI Inward Stock Depth': {
    label: 'Depth',
    explanation:
      'Foreign Direct Investment (FDI) stocks as a percentage of GDP',
    alignedLeft: true,
  },
  'FDI Inward Stock Breadth': {
    label: 'Breadth',
    explanation:
      'Geographic distribution of Foreign Direct Investment (FDI) stocks',
    alignedLeft: true,
  },
  'FDI Inflows Depth': {
    label: 'Depth',
    explanation:
      'Foreign Direct Investment (FDI) flows as a percentage of Gross Fixed Capital Formation',
    alignedLeft: true,
  },
  'FDI Inflows Breadth': {
    label: 'Breadth',
    explanation:
      'Geographic distribution of Foreign Direct Investment (FDI) flows',
    alignedLeft: true,
  },
  'Portfolio Equity Assets Stock Depth': {
    label: 'Depth',
    explanation:
      'Portfolio equity stocks as a percentage of market capitalization',
    alignedLeft: true,
  },
  'Portfolio Equity Assets Stock Breadth': {
    label: 'Breadth',
    explanation: 'Geographic distribution of portfolio equity stocks',
    alignedLeft: true,
  },
  'Scientific Research Collaboration Depth': {
    label: 'Depth',
    explanation:
      'Share of scholarly articles with co-authors located in more than one country',
    alignedLeft: true,
  },
  'Scientific Research Collaboration Breadth': {
    label: 'Breadth',
    explanation:
      'Geographic distribution of scholarly articles with co-authors located in more than one country',
    alignedLeft: true,
  },
  'Charges for the Use of Intellectual Property Depth': {
    label: 'Depth',
    explanation: 'International payments for the use of intellectual property as a share of Gross Domestic Product (GDP)',
    alignedLeft: true,
  },
  'Charges for the Use of Intellectual Property Breadth': {
    label: 'Breadth',
    explanation: 'Geographic distribution of payments for the use of intellectual property',
  },
  'International Patent Applications Depth': {
    label: 'Depth',
    explanation: 'International patent applications as a share of all patent applications (Note: Excludes China)',
  },
  'International Patent Applications Breadth': {
    label: 'Breadth',
    explanation: 'Geographic distribution of international patent applications (Note: Excludes China)',
  },
  'Tourist Arrivals Depth': {
    label: 'Depth',
    explanation: 'International tourist arrivals per capita',
    alignedLeft: true,
  },
  'Tourist Arrivals Breadth': {
    label: 'Breadth',
    explanation: 'Geographic distribution of international tourist arrivals',
    alignedLeft: true,
  },
  'International Students Depth': {
    label: 'Depth',
    explanation: 'Share of students enrolled in degree programs abroad',
    alignedLeft: true,
  },
  'International Students Breadth': {
    label: 'Breadth',
    explanation:
      'Geographic distribution of university students enrolled in degree programs abroad',
    alignedLeft: true,
  },
  'Immigration Depth': {
    label: 'Depth',
    explanation:
      'Share of world population living outside the country of birth',
    alignedLeft: true,
  },
  'Immigration Breadth': {
    label: 'Breadth',
    explanation:
      'Geographic distribution of world population living outside the country of birth',
    alignedLeft: true,
  },
};

export const glossaryTrendsMappings = {
  trendsSYC: {
    label: 'Select your choice',
    explanation: 'Please select the dimensions to be displayed',
    alignedLeft: true,
  },
  trendsOverall: {
    label: 'Global Connectedness',
    explanation:
      'Overall connectedness as measured by trade, capital, information and people flows combined',
    alignedLeft: true,
  },
  trendsTrade: {
    label: 'Trade',
    explanation: 'Index based on trade pillar',
    alignedLeft: true,
  },
  trendsCapital: {
    label: 'Capital',
    explanation:
      'Index based on capital pillar',
    alignedLeft: true,
  },
  trendsInformation: {
    label: 'Information',
    explanation:
      'Index based on information pillar',
    alignedLeft: true,
  },
  trendsPeople: {
    label: 'People',
    explanation:
      'Index based on people pillar',
    alignedLeft: true,
  },
  trendsSubOverall: {
    label: 'Overall',
    explanation: 'Depth and breadth combined',
    alignedLeft: true,
  },
  trendsSubDepth: {
    label: 'Depth',
    explanation: 'International flows relative to domestic activity',
    alignedLeft: true,
  },
  trendsSubBreadth: {
    label: 'Breadth',
    explanation: 'Geographic distribution of international flows',
    alignedLeft: true,
  },
};

export const glossaryCountryMappings = {
  countryOverall: {
    label: 'Global Connectedness',
    explanation: 'Select to view overall ranking ',
    alignedLeft: true,
  },
  countryTrade: {
    label: 'Trade',
    explanation: 'Select to view country ranks on trade',
  },
  countryCapital: {
    label: 'Capital',
    explanation: 'Select to view country ranks on capital',
  },
  countryInformation: {
    label: 'Information',
    explanation: 'Select to view country ranks on information',
    alignedLeft: true,
  },
  countryPeople: {
    label: 'People',
    explanation: 'Select to view country ranks on people',
  },
  countrySYC: {
    label: 'Select your choice',
    explanation: 'Please select the kind of ranking you want to display',
    alignedLeft: true,
  },
  changeLimit: {
    label: 'Change Limit',
    explanation: 'Expand or reduce the number of countries shown',
  },
  rank: {
    label: 'Rank',
    explanation: 'Rank (1 = most connected)',
    alignedLeft: true,
  },
  gainsLosses: {
    label: 'Gains/Losses',
    explanation: 'Five year rank change',
    alignedLeft: true,
  },
  breadth: {
    label: 'Breadth',
    explanation:
      'Geographic distribution of international flows/International flows relative to domestic activity',
  },
  depth: {
    label: 'Depth',
    explanation:
      'Geographic distribution of international flows/International flows relative to domestic activity',
  },
  score: {
    label: 'Score',
    explanation: 'Overall score',
  },
  country: {
    label: 'Country',
    explanation: 'Country name',
  },
  countryProfile: {
    label: 'Country Profile',
    explanation:
      'Please click to download country profile from DHL Global Connectedness Report 2024',
  },
  selectRegion: {
    label: 'Select Region / Country Group',
    explanation:
      'Filter ranking to focus on a particular region or country group',
  },
  selectLimit: {
    label: 'Select Limit',
    explanation: 'Expand or reduce the number of countries shown',
  },
};
