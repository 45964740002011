import React from 'react';
import { AxisBottom } from '@visx/axis';
import { func, number } from 'prop-types';
import CustomAxis from './CustomAxis';

function CustomAxisBottom({
  height, width, timeScale, numTicks, firstTick,
}) {
  return (
    <AxisBottom
      top={height}
      scale={timeScale}
      numTicks={numTicks}
      hideZero={false}
      firstTick={firstTick}
      width={width}
    >
      {CustomAxis}
    </AxisBottom>
  );
}

CustomAxisBottom.propTypes = {
  height: number.isRequired,
  width: number.isRequired,
  timeScale: func.isRequired,
  numTicks: number.isRequired,
  firstTick: number.isRequired,
};

export default CustomAxisBottom;
