import React from 'react';
import styles from '../styles/Glossary.module.scss';
import closeButton from '../assets/images/icon-cancel.svg';
import useGlobalState from '../hooks/useGlobalState';
import { glossaryMappings } from '../hooks/useGlossary';

function Glossary() {
  const { setState } = useGlobalState();

  const arr = Object.keys(glossaryMappings);

  return (
    <div className={styles.wrapper}>
      <span className={styles.heading}>Glossary</span>
      {arr.map((d) => (
        <div className={styles.glossaryItem}>
          <span className={styles.subHeading}>{glossaryMappings[d].label}</span>
          <p>{glossaryMappings[d].explanation}</p>
        </div>
      ))}
      <button
        type="button"
        className={styles.closeButton}
        onClick={() => setState({
          glossaryVisible: false,
        })}
      >
        <img src={closeButton} alt="Close Glossary" />
      </button>
    </div>
  );
}

export default Glossary;
