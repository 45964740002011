import React from 'react';
import { number, string } from 'prop-types';
import useGlobalState from '../hooks/useGlobalState';
import { ticksShape } from '../shapes';

function CustomAxis({
  axisToPoint,
  axisFromPoint,
  tickLength,
  ticks,
  width,
  firstTick,
  label,
}) {
  const {
    state: { hasDesktopFeatures },
  } = useGlobalState();
  const tickLabelSize = hasDesktopFeatures ? 14 : 10;
  const tickRotate = hasDesktopFeatures ? 0 : -45;
  const tickColor = '#000000';
  const axisCenter = (axisToPoint.x - axisFromPoint.x) / 2;
  return (
    <g className="custom-bottom-axis">
      <line x1={0} x2={width} stroke="#E7E7E7" strokeWidth="3" />
      <g className="vx-axis-tick">
        <text
          transform={`translate(${0}, ${29}) rotate(${tickRotate})`}
          fontSize={tickLabelSize}
          textAnchor="middle"
          fill={tickColor}
        >
          {firstTick}
        </text>
      </g>
      {ticks.map((tick) => {
        const tickX = tick.to.x;
        const tickY = tick.to.y + tickLabelSize + tickLength;
        return (
          <g
            key={`vx-tick-${tick.value}-${tickX}-${tickY}`}
            className="vx-axis-tick"
          >
            <line from={tick.from} to={tick.to} stroke={tickColor} />
            <text
              transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
              fontSize={tickLabelSize}
              textAnchor="middle"
              fill={tickColor}
            >
              {tick.formattedValue}
            </text>
          </g>
        );
      })}
      <text
        textAnchor="middle"
        transform={`translate(${axisCenter}, 50)`}
        fontSize="8"
      >
        {label}
      </text>
    </g>
  );
}

CustomAxis.propTypes = {
  axisToPoint: number.isRequired,
  axisFromPoint: number.isRequired,
  tickLength: number.isRequired,
  ticks: ticksShape.isRequired,
  label: string.isRequired,
  width: number.isRequired,
  firstTick: number.isRequired,
};

export default CustomAxis;
