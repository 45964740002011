import React, { useState } from 'react';

import { Bar } from '@visx/shape';
import { GridRows } from '@visx/grid';
import { AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';

import { scaleTime, scaleLinear } from '@visx/scale';
import { max, min } from 'd3-array';

import { arrayOf, number } from 'prop-types';
import CustomArea from './CustomArea';
import CustomAxisBottom from './CustomAxisBottom';
import Label from './Label';
import { unitMapping } from '../options/labelMappings';
import useGlobalState from '../hooks/useGlobalState';

import styles from '../styles/Graph.module.scss';
import { graphStylesShape, stringArrayShape, subKeyShape } from '../shapes';

const getDate = (d) => new Date(Date.UTC(d.year, 1, 1));

function Graph({
  data,
  activeKeys,
  activeSubKeys,
  graphStyles: {
    endYear, startYear, minY, showLabel, multiplier,
  },
  width,
  height,
}) {
  const [currentX, setCurrentX] = useState(undefined);
  const numTicks = endYear - startYear + 1;
  const {
    state: { hasDesktopFeatures },
  } = useGlobalState();

  const areas = activeKeys.flatMap((key, keyIndex) => activeSubKeys[key].map((subKey) => ({
    dataSet: data[keyIndex][key],
    key,
    subKey,
  })));

  if (areas.length === 0) {
    return null;
  }

  const minMaxValue = areas
    .flatMap(({ dataSet, subKey }) => dataSet.map((t) => t[subKey] * multiplier));
  const maxValue = max(minMaxValue) * 1.1;
  const minValue = minY === undefined ? min(minMaxValue) / 1.1 : minY;

  const firstPlot = data[0][activeKeys[0]];
  const timeScale = scaleTime({
    range: [0, width],
    domain: [min(firstPlot, getDate), max(firstPlot, getDate)],
  });

  const scoreScale = scaleLinear({
    range: [height, 0],
    domain: [minY || minValue, maxValue],
    nice: true,
  });

  const getYCoordinate = (dataSet, x, subKey) => {
    if (x === undefined) return false;
    const x0 = timeScale.invert(x).getFullYear();

    return scoreScale(
      dataSet.filter((el) => el.year === x0)[0][subKey] * multiplier,
    );
  };

  const handleTooltip = (e) => {
    const { x } = localPoint(e) || { x: 0 };
    const x0 = timeScale.invert(x);
    const currentYear = new Date(Date.UTC(x0.getFullYear(), 1, 1));

    setCurrentX(timeScale(currentYear));
  };

  const plots = areas.map(({ dataSet, key, subKey }) => ({
    area: key,
    valueKey: subKey,
    y: getYCoordinate(dataSet, currentX, subKey),
    x: currentX,
    value: scoreScale.invert(getYCoordinate(dataSet, currentX, subKey)),
  }));

  const plotsSorted = plots.sort((a, b) => b.y - a.y);

  const base = plots[0].y;
  const delta = 50;
  const margin = hasDesktopFeatures ? 28 : 25;
  const currentLabels = plotsSorted.map((a, i) => ({
    ...a,
    offset: i > 0 && a.y - plotsSorted[i - 1].y < delta,
    y: height - base + margin * i,
  }));

  const center = getDate(data[0][activeKeys[0]][Math.round(numTicks / 2) - 1]);
  const reversed = currentX > timeScale(center);

  return (
    <div className={styles.wrapper}>
      <svg
        width={width}
        height={height}
        style={{ overflow: 'visible' }}
        onMouseLeave={() => setCurrentX(undefined)}
      >
        <GridRows
          scale={scoreScale}
          width={width}
          height={height}
          stroke="#E7E7E7"
        />
        <CustomAxisBottom
          height={height}
          width={width}
          timeScale={timeScale}
          numTicks={numTicks}
          firstTick={startYear}
        />
        <AxisLeft
          top={0}
          scale={scoreScale}
          stroke="#E7E7E7"
          tickStroke="#E7E7E7"
          labelClassName={styles.label}
          label={
            showLabel && unitMapping[activeKeys[0]]
              ? unitMapping[activeKeys[0]][activeSubKeys[activeKeys[0]]]
              : null
          }
        />
        <Bar
          width={width}
          height={height}
          fill="transparent"
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
        />
        <Bar
          x={-25}
          y={0}
          width={50}
          height={height}
          fill="transparent"
          onMouseOver={() => setCurrentX(0)}
        />
        <Bar
          x={width - 25}
          offset={-25}
          y={0}
          width={50}
          height={height}
          fill="transparent"
          onMouseOver={() => setCurrentX(width)}
        />
        {currentX !== undefined && (
          <Bar
            x={currentX}
            y={0}
            width={1}
            height={height}
            className={styles.bar}
          />
        )}
        {areas.map(({ dataSet, key, subKey }) => (
          <CustomArea
            data={dataSet}
            timeScale={timeScale}
            scoreScale={scoreScale}
            key={`custom-area-${key}-${subKey}`}
            area={key}
            valueKey={subKey}
            currentCorridor={{
              x: currentX,
              y: getYCoordinate(dataSet, currentX, subKey),
            }}
            multiplier={multiplier}
          />
        ))}
      </svg>
      {currentX !== undefined
        && currentLabels.map((l) => (
          <Label
            x={l.x || 0}
            y={l.y}
            area={l.area}
            valueKey={l.valueKey}
            value={l.value}
            reversed={reversed}
            key={`graph-${l.area}-${l.valueKey}`}
          />
        ))}
    </div>
  );
}

Graph.propTypes = {
  data: arrayOf({
    any: [
      {
        year: number,
        overall: number,
        depth: number,
        breadth: number,
      },
    ],
  }).isRequired,
  activeKeys: stringArrayShape.isRequired,
  activeSubKeys: subKeyShape.isRequired,
  graphStyles: graphStylesShape.isRequired,
  width: number.isRequired,
  height: number.isRequired,
};

export default Graph;
