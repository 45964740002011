import 'core-js';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import ContextProvider from './store/GlobalContext';
import GCIClient from './GCIClient';
import './less/_.less';
import './styles/_elements.scss';
import './styles/_typography.scss';

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <GCIClient />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('gciClient-root'),
);
