import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/CountryResults.module.scss';
import { headingMappings } from '../options/countryOptions';

function CountryHeading({ region, year, field }) {
  return (
    <span className={styles.heading}>
      {headingMappings[field].label}
      : Most and least connected countries in
      {' '}
      {headingMappings[region].label}
      {' '}
      in
      {' '}
      {year}
    </span>
  );
}

export default CountryHeading;

CountryHeading.propTypes = {
  region: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
};
