export const areaStyles = {
  overall: {
    overall: { strokeColor: '#D40511' },
    breadth: { strokeColor: '#e56970' },
    depth: { strokeColor: '#f2b4b7' },
  },
  trade: {
    overall: { strokeColor: '#333333' },
    breadth: { strokeColor: '#8C8C8C' },
    depth: { strokeColor: '#CCCCCC' },
  },
  capital: {
    overall: { strokeColor: '#1E9E5E' },
    breadth: { strokeColor: '#78c59e' },
    depth: { strokeColor: '#bbe2ce' },
  },
  information: {
    overall: { strokeColor: '#4486F8' },
    breadth: { strokeColor: '#8fb6fb' },
    depth: { strokeColor: '#c7dafd' },
  },
  people: {
    overall: { strokeColor: '#CE46CE' },
    breadth: { strokeColor: '#FEA7FE' },
    depth: { strokeColor: '#FFB0FF' },
  },
};

export const areaLabelMapping = {
  overall: 'Global Connectedness',
  trade: 'Trade',
  capital: 'Capital',
  information: 'Information',
  people: 'People',
  'FDI Inflows': 'FDI Inflows',
  'FDI Inward Stock': 'FDI Inward Stock',
  Immigration: 'Immigration',
  'International Students': 'International Students',
  'Merchandise Exports': 'Merchandise Exports',
  'Portfolio Equity Asset Flows': 'Portfolio Equity Asset Flows',
  'Portfolio Equity Assets Stock': 'Portfolio Equity Assets Stock',
  'Printed Publications Exports': 'Printed Publications Exports',
  'Scientific Research Collaboration': 'Scientific Research Collaboration',
  'Services Exports': 'Services Exports',
  'Telephone Calls': 'Telephone Calls',
  'Tourist Arrivals': 'Tourist Arrivals',
};

export const unitMapping = {
  'FDI Inflows': { breadth: '%', depth: '%' },
  'FDI Inward Stock': { breadth: '%', depth: '%' },
  Immigration: { breadth: '%', depth: '%' },
  'International Students': { breadth: '%', depth: '%' },
  'Merchandise Exports': { breadth: '%', depth: '%' },
  'Portfolio Equity Asset Flows': { breadth: '%', depth: '%' },
  'Portfolio Equity Assets Stock': { breadth: '%', depth: '%' },
  'Printed Publications Exports': { breadth: '%', depth: '$' },
  'Scientific Research Collaboration': { breadth: '%', depth: '%' },
  'Services Exports': { breadth: '%', depth: '%' },
  'Telephone Calls': { breadth: '%', depth: '%' },
  'Tourist Arrivals': { breadth: '%', depth: 'per capita' },
  'Foreign Value Added': { breadth: '%', depth: '%' },
  'Announced Greenfield FDI': { breadth: '%', depth: '%' },
  'Mergers and Acquisitions Transactions': { breadth: '%', depth: '%' },
  'Charges for the Use of Intellectual Property': { breadth: '%', depth: '%' },
  'International Patent Applications': { breadth: '%', depth: '%' },
};

export const valueLabelMapping = {
  overall: 'Overall',
  depth: 'Depth',
  breadth: 'Breadth',
};
