import React, { useState, useRef, useEffect } from 'react';
import {
  SelectPicker,
  Toggle,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
} from 'rsuite';
import { ParentSize } from '@visx/responsive';
import { merge } from 'd3-array';
import DownloadData from './DownloadData';
import Graph from './Graph';
import Glossary from './Glossary';

import {
  displayByOptions,
  startYearOptions,
  endYearOptions,
  flowtrendsOptions,
  trendsOptions,
  trendsSubOptions,
  flowtrendsSubOptions,
} from '../options/trendsOptions';
import {
  initialFlowtrendsFilter,
  initialFlowtrendsSubFilter,
  initialTrendsFilter,
  initialTrendsSubFilter,
} from '../options/initialStates';
import { glossaryFlowtrendsMappings } from '../options/glossaryMappings';
import RoutesSwitcher from './RoutesSwitcher';
import RenderedState from './RenderedState';

import styles from '../styles/GlobalResults.module.scss';
import useGlobalState from '../hooks/useGlobalState';
import useGlossary from '../hooks/useGlossary';
import trackEvent from '../helpers/tracking';

const defaultStartYear = 2001;
const defaultEndYear = 2023;

const getActiveKeys = (filter) => Object.keys(filter).filter((k) => filter[k]);

function getGraphStyle(displayBy, flowtrendsFilter, flowtrendsSubFilter) {
  if (displayBy === 'global_connectedness') {
    return {
      multiplier: 1,
    };
  }

  if (
    flowtrendsSubFilter[getActiveKeys(flowtrendsFilter)[0]] === 'depth'
    && (flowtrendsFilter['Tourist Arrivals']
      || flowtrendsFilter['Printed Publications Exports'])
  ) {
    return {
      multiplier: 1,
      showLabel: true,
    };
  }

  if (flowtrendsFilter['Portfolio Equity Asset Flows']) {
    return {
      minY: -0.6,
      multiplier: 100,
      showLabel: true,
    };
  }

  return {
    multiplier: 100,
    showLabel: true,
  };
}

const formatValues = (filter) => {
  const obj = {};
  for (let i = 0; i < Object.keys(filter).length; i += 1) {
    obj[Object.keys(filter)[i]] = [Object.values(filter)[i]];
  }
  return obj;
};

const filterData = (
  graphData,
  displayBy,
  trendsFilter,
  trendsSubFilter,
  flowtrendsFilter,
  startYear,
  endYear,
) => {
  try {
    if (!graphData) {
      return null;
    }
    if (!graphData || Object.keys(graphData).length === 0) {
      return null;
    }
    if (Object.keys(trendsFilter).filter((k) => trendsFilter[k]).length === 0) {
      return null;
    }
    if (
      merge(
        Object.keys(trendsFilter)
          .filter((k) => trendsFilter[k])
          .map((t) => trendsSubFilter[t]),
      ).length === 0
    ) {
      return null;
    }
    const activeKeys = displayBy === 'global_connectedness'
      ? Object.keys(trendsFilter).filter((k) => trendsFilter[k])
      : Object.keys(flowtrendsFilter).filter((k) => flowtrendsFilter[k]);
    return displayBy === 'global_connectedness'
      ? activeKeys.map((k) => ({
        [k]: graphData[k][startYear].filter((d) => d.year <= endYear),
      }))
      : activeKeys.map((k) => ({
        [k]: graphData[k].data.filter(
          (d) => d.year >= startYear && d.year <= endYear,
        ),
      }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    // eslint-enable-next-line no-console
    return null;
  }
};

function GlobalResults() {
  const [displayBy, setDisplayBy] = useState('global_connectedness');
  const {
    state: { individualFlowtrends, trends, glossaryVisible },
  } = useGlobalState();
  const g = useGlossary;

  const snapshotWrapper = useRef();

  const [flowtrendsFilter, setFlowtrendsFilter] = useState(
    initialFlowtrendsFilter,
  );
  const [flowtrendsSubFilter, setFlowtrendsSubFilter] = useState(
    initialFlowtrendsSubFilter,
  );

  if (window.location.search) {
    new URLSearchParams(window.location.search)
      .get('gci-active-trends')
      .split(',')
      .forEach((k) => {
        if (Object.prototype.hasOwnProperty.call(initialTrendsFilter, k)) {
          initialTrendsFilter[k] = true;
        }
      });
  }

  const [trendsFilter, setTrendsFilter] = useState(initialTrendsFilter);
  const [trendsSubFilter, setTrendsSubFilter] = useState(initialTrendsSubFilter);

  const [startYear, setStartYear] = useState(defaultStartYear);
  const [endYear, setEndYear] = useState(defaultEndYear);

  const filteredEndYear = endYearOptions.filter((y) => y.value > startYear);
  const filteredStartYear = startYearOptions
    .filter((y) => y.value < endYear)
    .filter((y) => (displayBy === 'individual_flow_trends' && flowtrendsFilter['Announced Greenfield FDI'] ? y.value > 2002 : y.value >= defaultStartYear))
    .filter((y) => (displayBy === 'individual_flow_trends' && flowtrendsFilter['Charges for the Use of Intellectual Property'] && flowtrendsSubFilter['Charges for the Use of Intellectual Property'] === 'breadth' ? y.value > 2004 : y.value >= defaultStartYear));

  useEffect(() => {
    setStartYear(filteredStartYear.at(0).value);
    setEndYear(filteredEndYear.at(-1).value);
  }, [displayBy, flowtrendsFilter, flowtrendsSubFilter]);

  const handleDisplayByChange = (value) => {
    trackEvent({
      event: 'display_by_change',
      value: `${value}`,
    });

    setDisplayBy(value);
  };

  const handleStartYearChange = (value) => {
    trackEvent({
      event: 'filter_change',
      type: 'start_year',
      value: `${value}`,
    });

    setStartYear(value);
  };

  const handleEndYearChange = (value) => {
    trackEvent({
      event: 'filter_change',
      type: 'end_year',
      value: `${value}`,
    });

    setEndYear(value);
  };

  const handleFilterChange = (type, key) => {
    trackEvent({
      event: 'filter_change',
      type: `${type}`,
      value: `${key}`,
    });

    if (type === 'individual_flow_trends') {
      setFlowtrendsFilter(() => ({
        [key]: true,
      }));
    }

    if (type === 'global_connectedness') {
      setTrendsFilter((prevFilter) => ({
        ...prevFilter,
        [key]: !prevFilter[key],
      }));
    }
  };

  const handleSubFilterChange = (type, key, value) => {
    trackEvent({
      event: 'sub_filter_change',
      filter: `${type}`,
      sub_filter: `${key}`,
      value: `${value}`,
    });

    if (type === 'individual_flow_trends') {
      setFlowtrendsSubFilter((prev) => ({
        ...prev,
        [key]: value,
      }));
    }

    if (type === 'global_connectedness') {
      setTrendsSubFilter((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const graphData = displayBy === 'individual_flow_trends' ? individualFlowtrends : trends;

  const filteredData = filterData(
    graphData,
    displayBy,
    trendsFilter,
    trendsSubFilter,
    flowtrendsFilter,
    startYear,
    endYear,
  );

  const activeKeys = displayBy === 'global_connectedness'
    ? Object.keys(trendsFilter).filter((k) => trendsFilter[k])
    : Object.keys(flowtrendsFilter).filter((k) => flowtrendsFilter[k]);

  const activeSubKeys = displayBy === 'global_connectedness'
    ? trendsSubFilter
    : formatValues(flowtrendsSubFilter);

  const graphStyles = {
    ...getGraphStyle(displayBy, flowtrendsFilter, flowtrendsSubFilter),
    startYear,
    endYear,
  };

  const source = displayBy === 'individual_flow_trends'
    && flowtrendsSubFilter[getActiveKeys(flowtrendsFilter)[0]] === 'depth'
    && graphData[getActiveKeys(flowtrendsFilter)[0]].depth_source;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <RoutesSwitcher />
        <div className={styles.firstFilterWrapper}>
          <div className={styles.row}>
            <span className={styles.subHeading}>Select Element</span>
            <SelectPicker
              value={displayBy}
              data={displayByOptions}
              searchable={false}
              onChange={(value) => handleDisplayByChange(value)}
              cleanable={false}
              className={styles.selectDisplayBy}
              style={{ width: '150px' }}
              menuClassName="gciClient-menu-dropdown"
            />
          </div>
          <div className={`${styles.row} ${styles.stableRow}`}>
            <div className={`${styles.col} ${styles.hasHyphen}`}>
              <span className={styles.subHeading}>
                {g({ key: 'selectDate' })}
              </span>
              <SelectPicker
                value={startYear}
                data={filteredStartYear}
                onChange={(value) => handleStartYearChange(value)}
                onClean={() => handleStartYearChange(defaultStartYear)}
                searchable={false}
                className={styles.calendarIcon}
                cleanable={false}
                menuClassName="gciClient-menu-dropdown"
              />
            </div>
            <div className={styles.col}>
              <SelectPicker
                value={endYear}
                data={filteredEndYear}
                onChange={(value) => handleEndYearChange(value)}
                onClean={() => handleEndYearChange(defaultEndYear)}
                searchable={false}
                className={styles.calendarIcon}
                cleanable={false}
                menuClassName="gciClient-menu-dropdown"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.filterWrapper}>
        <span className={styles.subHeading}>
          {displayBy === 'individual_flow_trends'
            ? g({ key: 'flowtrendsSYC' })
            : g({ key: 'trendsSYC' })}
        </span>
        {displayBy === 'individual_flow_trends' && (
          <>
            <div>
              <SelectPicker
                value={getActiveKeys(flowtrendsFilter)[0]}
                onChange={(value) => {
                  handleFilterChange('individual_flow_trends', value);
                }}
                onClean={() => handleFilterChange('individual_flow_trends', 'FDI Inflows')}
                data={flowtrendsOptions}
                searchable={false}
                cleanable={false}
                style={{ width: 300 }}
                menuClassName="gciClient-menu-dropdown"
              />
            </div>
            {flowtrendsOptions.map((f) => {
              if (f.label === 'Foreign Value Added'
              || f.label === 'FDI Inflows') {
                return (
                  <div
                    className={styles.filterItem}
                    style={{ display: 'block' }}
                    key={`flowtrends-option-reduced-${f.value}`}
                  >
                    <RadioGroup
                      inline
                      name="flowtrends-filter"
                      value={flowtrendsSubFilter[f.value]}
                      onChange={(value) => handleSubFilterChange(
                        'individual_flow_trends',
                        f.value,
                        value,
                      )}
                      className={`sub-filter ${
                        getActiveKeys(flowtrendsFilter)[0] === f.value
                          ? 'shown'
                          : 'hidden'
                      } `}
                    >
                      <Radio value="depth" key={`${f}-subvalue`}>
                        {g({ key: `${f.label} Depth` })}
                      </Radio>
                    </RadioGroup>
                  </div>
                );
              }
              return (
                <div
                  className={styles.filterItem}
                  style={{ display: 'block' }}
                  key={`flowtrends-option-default-${f.value}`}
                >
                  <RadioGroup
                    inline
                    name="flowtrends-filter"
                    value={flowtrendsSubFilter[f.value]}
                    onChange={(value) => handleSubFilterChange(
                      'individual_flow_trends',
                      f.value,
                      value,
                    )}
                    className={`sub-filter ${
                      getActiveKeys(flowtrendsFilter)[0] === f.value
                        ? 'shown'
                        : 'hidden'
                    } `}
                  >
                    {flowtrendsSubOptions.map((fs) => (
                      <Radio
                        value={fs.value}
                        key={`flowtrends-subotion-default-${fs.value}`}
                      >
                        {g({ key: `${f.label} ${fs.label}` })}
                      </Radio>
                    ))}
                  </RadioGroup>
                </div>
              );
            })}
          </>
        )}
        {displayBy === 'global_connectedness'
          && trendsOptions.map((t) => (
            <div
              className={`${styles.filterItem} ${styles[`toggle${t.value}`]}`}
              key={`trends-filter-item-${t.value}`}
            >
              <Toggle
                value={trendsFilter[t.value]}
                defaultChecked={trendsFilter[t.value]}
                onChange={() => handleFilterChange('global_connectedness', t.value)}
                className="spec--filter-item"
              />
              <span>{g({ key: t.glossarySel })}</span>
              <CheckboxGroup
                inline
                name="trends-filter"
                value={trendsSubFilter[t.value]}
                onChange={(value) => handleSubFilterChange('global_connectedness', t.value, value)}
                className={styles.subFilter}
              >
                {trendsSubOptions.map((ts) => (
                  <Checkbox
                    value={ts.value}
                    key={`trends-subfilter-item-${ts.value}`}
                  >
                    {g({ key: ts.glossarySel })}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </div>
          ))}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.subHeader}>
          {displayBy === 'individual_flow_trends' ? (
            <span className={styles.heading}>{activeKeys[0]}</span>
          ) : (
            <span className={styles.heading}>Global Flows</span>
          )}
          <div className={styles.controls}>
            {filteredData ? (
              <DownloadData
                node={snapshotWrapper}
                data={filteredData}
                dataFormat="graph"
                source="Global Results"
                activeSubKeys={
                  displayBy === 'global_connectedness'
                    ? trendsSubFilter
                    : formatValues(flowtrendsSubFilter)
                }
              />
            ) : (
              <RenderedState simpleDisplay />
            )}
          </div>
        </div>
        <div ref={snapshotWrapper} className={styles.snapshotWrapper}>
          <div className={styles.graphWrapper}>
            <ParentSize>
              {({ width, height }) => (filteredData ? (
                <Graph
                  width={width}
                  height={height}
                  data={filteredData}
                  activeKeys={activeKeys}
                  activeSubKeys={activeSubKeys}
                  graphStyles={graphStyles}
                />
              ) : (
                <RenderedState />
              ))}
            </ParentSize>
          </div>
          {filteredData
            && glossaryFlowtrendsMappings[
              `${activeKeys[0]} ${activeSubKeys[activeKeys[0]][0]?.replace(
                /^\w/,
                (c) => c.toUpperCase(),
              )}`
            ] && (
              <p className={styles.explanation}>
                {
                  glossaryFlowtrendsMappings[
                    `${activeKeys[0]} ${activeSubKeys[activeKeys[0]][0].replace(
                      /^\w/,
                      (c) => c.toUpperCase(),
                    )}`
                  ].explanation
                }
              </p>
          )}
          {source && (
            <div className={styles.source}>
              <span>Source:</span>
              {' '}
              {source}
            </div>
          )}
          <p style={{ margin: '1rem 2rem' }}>
            Note: 2023 values are projected (not final).
          </p>
        </div>
        {glossaryVisible && <Glossary />}
      </div>
    </div>
  );
}

export default GlobalResults;
