import React, { createContext, useMemo } from 'react';
import { useSetState } from 'react-use';
import PropTypes from 'prop-types';

export const Context = createContext(null);

Context.displayName = 'GlobalContext';

const initialState = {
  dataLoaded: false,
  dataLoadingError: false,
  dataSet: false,
  route: 'global',
  individualFlowtrends: {},
  trends: {},
  countryResults: {},
  glossaryVisible: false,
  hasDekstopFeatures: false,
};

function ContextProvider({ children }) {
  const [state, setState] = useSetState(initialState);
  const value = useMemo(() => ({ state, setState }), [state, setState]);

  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
}

export default ContextProvider;

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
