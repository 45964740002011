import React from 'react';
import PropTypes from 'prop-types';
import domtoimage from 'dom-to-image';
import { Dropdown } from 'rsuite';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import useGlobalState from '../hooks/useGlobalState';
import styles from '../styles/DownloadData.module.scss';
import trackEvent from '../helpers/tracking';

function DownloadData({
  node, data, activeSubKeys, dataFormat, source,
}) {
  const {
    state: { hasDesktopFeatures },
  } = useGlobalState();

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line
    for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff
    return buf;
  };

  const createImageSnapshot = (targetNode, sourceDataFormat) => {
    domtoimage
      .toJpeg(targetNode)
      .then((dataUrl) => {
        saveAs(dataUrl, `GCI Snapshot ${source}.jpg`);
      })
      .then(() => {
        trackEvent({
          event: 'download_data',
          type: sourceDataFormat,
          value: 'image',
        });
      })
      .catch((error) => {
        /* eslint-disable no-console */
        console.error('Error while creating your snapshot: ', error);
        /* eslint-enable no-console */
      });
  };

  const createCSVFromData = (sourceData, subKeys, sourceDataFormat) => {
    const citationNote = 'Please cite as: Altman, Steven A. and Caroline R. Bastian. DHL Global Connectedness Report 2024. Bonn: DHL Group. DOI:10.58153/7jt4h-p0738';
    let header;
    let rows;

    if (sourceDataFormat === 'graph') {
      const keys = sourceData.map((d) => Object.keys(d).join(''));

      header = `${['Pillars', 'Dimensions'].join(',')},${sourceData[0][keys[0]]
        .map((d) => d.year)
        .join(',')}\n`;

      rows = keys
        .map((r, i) => subKeys[r]
          .map(
            (sk) => `${[r, sk].join(',')},${sourceData[i][r].map((d) => d[sk])}`,
          )
          .join('\n'))
        .join('\n');
    }

    if (sourceDataFormat === 'table') {
      header = `${[
        'Country',
        'Rank',
        'Gains/Losses',
        'Depth Score',
        'Breadth Score',
        'Score',
      ].join(',')}\n`;

      rows = sourceData
        .map((d, i) => Object.values({
          country: d.country,
          rank: i + 1,
          change: d.change,
          depth_score: d.depth_score,
          breadth_score: d.breadth_score,
          score: d.score,
        }))
        .join('\n');
    }

    const csvContent = `${header}${rows}\n${citationNote}`;

    saveAs(
      new Blob([csvContent], {
        type: 'text/csv;charset=utf-8',
      }),
      `GCI Data ${source}.csv`,
      { autoBom: true },
    );

    trackEvent({
      event: 'download_data',
      type: sourceDataFormat,
      value: 'csv',
    });
  };

  const createXLSXfromData = (sourceData, subKeys, sourceDataFormat) => {
    const citationNote = 'Please cite as: Altman, Steven A. and Caroline R. Bastian. DHL Global Connectedness Report 2024. Bonn: DHL Group. DOI:10.58153/7jt4h-p0738';
    let header;
    let rows;

    if (sourceDataFormat === 'graph') {
      const keys = sourceData.map((d) => Object.keys(d).join(''));
      header = [
        'Pillars',
        'Dimensions',
        ...sourceData[0][keys[0]].map((d) => d.year),
      ];
      rows = [];

      keys.forEach((r, i) => subKeys[r]
        .forEach((sk) => rows.push([r, sk, ...sourceData[i][r].map((d) => d[sk])])));
    }

    if (sourceDataFormat === 'table') {
      header = [
        'Country',
        'Rank',
        'Gains/Losses',
        'Depth Score',
        'Breadth Score',
        'Score',
      ];

      rows = sourceData.map((d, i) => Object.values({
        country: d.country,
        rank: i + 1,
        change: d.change,
        depth_score: d.depth_score,
        breadth_score: d.breadth_score,
        score: d.score,
      }));
    }

    rows.push([]);
    rows.push([citationNote]);

    const wsData = [header, ...rows];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();

    wb.Sheets['GCI Sheet'] = ws;
    wb.Props = {
      Title: 'GCI Data',
      CreatedDate: new Date(),
    };

    wb.SheetNames.push('GCI Sheet');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      `GCI Data ${source}.xlsx`,
    );

    trackEvent({
      event: 'download_data',
      type: sourceDataFormat,
      value: 'xlsx',
    });
  };

  const isIE = window.document.documentMode;

  return (
    <div className={styles.wrapper}>
      <Dropdown className={styles.downloadButton} placement="bottomEnd">
        {!isIE && hasDesktopFeatures && (
          <Dropdown.Item
            onClick={() => createImageSnapshot(node.current, dataFormat)}
          >
            Download as image
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={() => createCSVFromData(data, activeSubKeys, dataFormat)}
        >
          Download as CSV
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => createXLSXfromData(data, activeSubKeys, dataFormat)}
        >
          Download as XLSX
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
}

export default DownloadData;

DownloadData.propTypes = {
  node: PropTypes.objectOf({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  dataFormat: PropTypes.string.isRequired,
  data: PropTypes.arrayOf([
    {
      any: [
        {
          year: PropTypes.number,
          overall: PropTypes.number,
          depth: PropTypes.number,
          breadth: PropTypes.number,
          score: PropTypes.number,
        },
      ],
    },
  ]).isRequired,
  activeSubKeys: PropTypes.objectOf({
    any: [PropTypes.string],
  }),
  source: PropTypes.string.isRequired,
};

DownloadData.defaultProps = {
  activeSubKeys: null,
};
