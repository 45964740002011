import React from 'react';
import PropTypes from 'prop-types';
import { Area } from '@visx/shape';
import { curveLinear } from '@visx/curve';
import { areaStyles } from '../options/labelMappings';
import styles from '../styles/CustomArea.module.scss';

function CustomArea({
  data,
  timeScale,
  scoreScale,
  valueKey,
  currentCorridor,
  area,
  multiplier,
}) {
  const getDate = (d) => new Date(Date.UTC(d.year, 1, 1));
  const getScore = (d) => d[valueKey] * multiplier;

  return (
    <>
      <g>
        <Area
          data={data}
          x={(d) => timeScale(getDate(d))}
          y={(d) => scoreScale(getScore(d))}
          strokeWidth={2}
          fill="transparent"
          stroke={
            areaStyles[area]
              ? areaStyles[area][valueKey].strokeColor
              : '#D40511'
          }
          curve={curveLinear}
          className={`${styles.area} spec--custom-area`}
        />
      </g>
      {currentCorridor.x !== undefined && (
        <g className={styles.group}>
          <circle
            r={8}
            cx={currentCorridor.x}
            cy={currentCorridor.y}
            fill={
              areaStyles[area]
                ? areaStyles[area][valueKey].strokeColor
                : '#D40511'
            }
            style={{ opacity: '.2' }}
          />

          <circle
            r={4.5}
            cx={currentCorridor.x}
            cy={currentCorridor.y}
            fill="white"
          />

          <circle
            r={4}
            cx={currentCorridor.x}
            cy={currentCorridor.y}
            fill={
              areaStyles[area]
                ? areaStyles[area][valueKey].strokeColor
                : '#D40511'
            }
            stroke="white"
            strokeWidth={2}
          />
        </g>
      )}
    </>
  );
}

export default CustomArea;

CustomArea.propTypes = {
  data: PropTypes.arrayOf({
    year: PropTypes.number,
    overall: PropTypes.number,
    depth: PropTypes.number,
    breadth: PropTypes.number,
  }).isRequired,
  timeScale: PropTypes.func.isRequired,
  scoreScale: PropTypes.func.isRequired,
  valueKey: PropTypes.string.isRequired,
  currentCorridor: PropTypes.objectOf(PropTypes.number).isRequired,
  area: PropTypes.string.isRequired,
  multiplier: PropTypes.number.isRequired,
};
