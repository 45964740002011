import {
  array,
  arrayOf,
  bool,
  number,
  object,
  objectOf,
  shape,
  string,
} from 'prop-types';

export const tickShape = shape({
  to: shape({
    x: number,
    y: number,
  }),
});

export const graphStylesShape = shape({
  multiplier: number.isRequired,
  startYear: number.isRequired,
  endYear: number.isRequired,
  minY: number,
  showLabel: bool,
});

export const dataShape = arrayOf(object);

export const stringArrayShape = arrayOf(string);

export const ticksShape = arrayOf(tickShape);

export const subKeyShape = objectOf(array);
