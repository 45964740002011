import React, { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import GlobalResults from './components/GlobalResults';
import CountryResults from './components/CountryResults';
import styles from './styles/GCIClient.module.scss';
import RenderedState from './components/RenderedState';
import useGlobalState from './hooks/useGlobalState';
import licenseTXT from './assets/licenses.txt';

const fetchJson = (url) => fetch(url).then((res) => res.json());

function GCIClient() {
  const {
    state: { route, dataLoaded },
    setState,
  } = useGlobalState();

  const { width } = useWindowSize();

  useEffect(() => {
    (async () => {
      try {
        const urls = [
          process.env.REACT_APP_URL_DATA_REPORTS,
          process.env.REACT_APP_URL_COUNTRY_DATA,
        ];
        const [dataReports, countryData] = await Promise.all(
          urls.map(fetchJson),
        );
        setState({
          individualFlowtrends: dataReports.filter(
            (d) => d.type === 'flowtrends',
          )[0].data,
          trends: dataReports.filter((d) => d.type === 'trends')[0].data,
          countryResults: dataReports.filter((d) => d.type === 'countries')[0]
            .data,
          countryData,
          dataLoaded: true,
        });
      } catch (err) {
        setState({
          dataLoadingError: true,
        });
      }
    })();
  }, [setState]);

  useEffect(() => {
    if (width < 700) {
      setState({
        hasDesktopFeatures: false,
      });
    } else {
      setState({
        hasDesktopFeatures: true,
      });
    }
  }, [width, setState]);

  return (
    <div className={styles.wrapper}>
      {dataLoaded ? (
        <>
          {route === 'global' && <GlobalResults />}
          {route === 'country' && <CountryResults />}
        </>
      ) : (
        <RenderedState />
      )}
      <a
        href={licenseTXT}
        className={styles.licenseTXT}
        target="_blank"
        rel="noopener noreferrer"
      >
        License info
      </a>
    </div>
  );
}

export default GCIClient;
