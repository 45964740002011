import React from 'react';
import PropTypes from 'prop-types';
import {
  areaStyles,
  areaLabelMapping,
  unitMapping,
  valueLabelMapping,
} from '../options/labelMappings';
import styles from '../styles/CustomArea.module.scss';
import useGlobalState from '../hooks/useGlobalState';

function Label({
  x, y, area, valueKey, value, reversed,
}) {
  const {
    state: { hasDesktopFeatures },
  } = useGlobalState();
  const y0 = y - 13;
  const x0 = x + 20;

  return (
    <div
      style={{
        position: 'absolute',
        bottom: `${y0}px`,
        left: reversed ? `${x0 - 40}px` : `${x0}px`,
        transform: reversed && 'translateX(-100%)',
        height: hasDesktopFeatures ? '28px' : '25px',
      }}
      className={styles.labelWrapper}
    >
      <div
        className={reversed ? styles.labelReversed : styles.label}
        style={{
          borderColor: `${
            areaStyles[area]
              ? areaStyles[area][valueKey].strokeColor
              : '#D40511'
          }`,
        }}
      >
        {hasDesktopFeatures && (
          <>
            <span className={styles.labelHeading}>
              {areaLabelMapping[area]}
              {' '}
              (
              {valueLabelMapping[valueKey]}
              )
            </span>
            {': '}
          </>
        )}
        <span>
          {value ? value.toFixed(2) : null}
          {' '}
          {unitMapping[area] && (unitMapping[area][valueKey] || '')}
        </span>
      </div>
    </div>
  );
}

export default Label;

Label.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  area: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  reversed: PropTypes.bool.isRequired,
};
